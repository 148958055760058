import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogHeadingComponent, DialogContentComponent } from '@k5next/ui-components';

/**
 * Parameter für die Komponente "k5-bild-vorschau-dialog"
 *
 * bildSource: Enthält den Wert, der im src-Attribut des enthaltenen img-Element als Quelle angegeben wird.
 * bildDescription: Enthält die textuelle Beschreibung des Bildes für Screen Reader.
 * bildUnavailableText: Enthält den Hinweis für den Anwender, wenn das Bild nicht angezeigt werden kann.
 */
export interface BildVorschauDialogData {
    bildSource?: string;
    bildDescription?: string;
    bildUnavailableText?: string;
}

/**
 * Komponente "k5-bild-vorschau-dialog"
 *
 * Dienst zur Anzeige einer Bildvorschau als Dialog, ohne weitere Funktionen.
 * Tritt bei der Anzeige des Bildes im img-Element ein Fehler auf, wird anstelle
 * des Bildes ein Hinweistext angezeigt.
 */
@Component({
    selector: 'k5-bild-vorschau-dialog',
    standalone: true,
    templateUrl: './bild-vorschau-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, DialogHeadingComponent, DialogContentComponent]
})
export class BildVorschauDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: BildVorschauDialogData) {}

    // Property mit dem Status, dass das Bild nicht verfügbar ist
    bildUnavailable: boolean = false;

    /**
     * Verarbeitet die Situation, dass das Bild nicht verfügbar ist.
     * Die Funktion setzt dabei die Variable 'bildUnavailable' auf 'true', sodass
     * das img-Element in der View versteckt und der Text angezeigt wird.
     */
    handleBildUnavailable(): void {
        // Bild ist nicht verfügbar
        this.bildUnavailable = true;
    }
}
