<k5next-dialog-heading heading="Vorschau" data-cy="vorschau-dialog-heading"></k5next-dialog-heading>
<k5next-dialog-content data-cy="vorschau-dialog-content" class="p-2">
    <img
        [src]="data.bildSource"
        [alt]="data.bildDescription"
        data-cy="vorschau-dialog-bild"
        (error)="handleBildUnavailable()"
        *ngIf="!bildUnavailable"
    />
    <div data-cy="vorschau-dialog-bild-unavailable-text" *ngIf="bildUnavailable">
        {{ data.bildUnavailableText }}
    </div>
</k5next-dialog-content>
